import { default as _91id_939pwcegFMDHMeta } from "/codebuild/output/src4238403719/src/mosaic-land/pages/import/[id].vue?macro=true";
import { default as indexdCc4dyRPGlMeta } from "/codebuild/output/src4238403719/src/mosaic-land/pages/import/index.vue?macro=true";
import { default as indexRcxPg3Xt7TMeta } from "/codebuild/output/src4238403719/src/mosaic-land/pages/index.vue?macro=true";
import { default as logintl9wVMejTEMeta } from "/codebuild/output/src4238403719/src/mosaic-land/pages/login.vue?macro=true";
import { default as newqIo5kJHHeKMeta } from "/codebuild/output/src4238403719/src/mosaic-land/pages/trade/new.vue?macro=true";
export default [
  {
    name: _91id_939pwcegFMDHMeta?.name ?? "import-id",
    path: _91id_939pwcegFMDHMeta?.path ?? "/import/:id()",
    meta: _91id_939pwcegFMDHMeta || {},
    alias: _91id_939pwcegFMDHMeta?.alias || [],
    redirect: _91id_939pwcegFMDHMeta?.redirect,
    component: () => import("/codebuild/output/src4238403719/src/mosaic-land/pages/import/[id].vue").then(m => m.default || m)
  },
  {
    name: indexdCc4dyRPGlMeta?.name ?? "import",
    path: indexdCc4dyRPGlMeta?.path ?? "/import",
    meta: indexdCc4dyRPGlMeta || {},
    alias: indexdCc4dyRPGlMeta?.alias || [],
    redirect: indexdCc4dyRPGlMeta?.redirect,
    component: () => import("/codebuild/output/src4238403719/src/mosaic-land/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexRcxPg3Xt7TMeta?.name ?? "index",
    path: indexRcxPg3Xt7TMeta?.path ?? "/",
    meta: indexRcxPg3Xt7TMeta || {},
    alias: indexRcxPg3Xt7TMeta?.alias || [],
    redirect: indexRcxPg3Xt7TMeta?.redirect,
    component: () => import("/codebuild/output/src4238403719/src/mosaic-land/pages/index.vue").then(m => m.default || m)
  },
  {
    name: logintl9wVMejTEMeta?.name ?? "login",
    path: logintl9wVMejTEMeta?.path ?? "/login",
    meta: logintl9wVMejTEMeta || {},
    alias: logintl9wVMejTEMeta?.alias || [],
    redirect: logintl9wVMejTEMeta?.redirect,
    component: () => import("/codebuild/output/src4238403719/src/mosaic-land/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newqIo5kJHHeKMeta?.name ?? "trade-new",
    path: newqIo5kJHHeKMeta?.path ?? "/trade/new",
    meta: newqIo5kJHHeKMeta || {},
    alias: newqIo5kJHHeKMeta?.alias || [],
    redirect: newqIo5kJHHeKMeta?.redirect,
    component: () => import("/codebuild/output/src4238403719/src/mosaic-land/pages/trade/new.vue").then(m => m.default || m)
  }
]